<template>
  <div class="holiday">
    <h1>This is holiday page TEST</h1>
    {{ test }}
    {{ page }}
    {{ $route.query }}
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      test: 1
    };
  }
};
</script>
